// // MAIN SECTION STYLE
// // __________________________________________________________



.main{
    position: relative;
    overflow-x: hidden;

    &-illus-container{
        pointer-events: none;
        @extend %rempimg;
        @include pseudo-el();
        @include box(10%);
        top: 0;
        left: 0;

        &::after{
            @include pseudo-el();
            background: url('src/img/illus/quote-form.svg') no-repeat;
            background-size: contain;
            background-position: left top;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -3;
        }
    }
}



// RESPONSIVE MD
// ________________________________________________________________________________________


@media screen and (min-width: 768px){
    .main{
        &-illus-container{
            display: block;
            @include box(28%);

            &::after{
                display: block;
            }
        }
    }
}




// RESPONSIVE XL
// ________________________________________________________________________________________


@media screen and (min-width: 1200px){
    .main{
        max-width: 1920px;
        margin: 0 auto;
    }
}