// PROJECT CARD STYLE
// __________________________________________________________

.card{
    &-offer{
        min-height: 100%;
        border-radius: 50px 50px 0 50px;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        position: relative;

        &::before{
            @include pseudo-el();
            border: 1px solid;
            border-radius: 50px 50px 0 50px;
            z-index: -1;
        }

        &::before{
            height: calc(100% + 8px);
            width: calc(100% + 16px);
            border-color: $font-blue;
            bottom: 0;
            left: -8px;
        }
        
        &__pro{
            &::before{
                border-color: $font-green;
            }  
        }
    }
    
    &-numbers{
        min-height: 100%;
    }

}




// RESPONSIVE LG
// ________________________________________________________________________________________


@media screen and (min-width: 992px) {
    .card{
        &-offer{
            &::before{
                width: calc(100% + 32px);
                height: calc(100% + 16px);
                left: -16px;
            }

            &::after{
                @include pseudo-el();
                border: 1px solid;
                border-radius: 50px 50px 0 50px;
                z-index: -1;
                height: calc(100% - 24px);
                width: calc(100% + 16px);
                bottom: -24px;
                right: -32px;
                border-color: $font-green;
            }

            &__pro{
                &::after{
                    border-color: $font-blue;
                } 
            }
        }
    }
}