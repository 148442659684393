
.menu{
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-container{
        background-color: white;
        position: fixed;
        z-index: 3;
        width: 100%;
        top: 0;
        left: 0;
    }
}

.navigation{
    &-menu{
        list-style-type: none;
        text-decoration: none;
    }

    &-link{
        color: $font-green;
        list-style-type: none;
        text-decoration: none;
        font-size: 2.5rem;
        padding: 1.2rem 0;
        position: relative;
        border: none;
        transition: color .4s cubic-bezier(0.77,0.2,0.05,1.0);

        &:hover, &:focus, &:active{
            text-decoration: none;
            color: $font-blue;  
            transition: color .4s cubic-bezier(0.77,0.2,0.05,1.0);
        }

        &::after{
            @include pseudo-el();
            background-color: transparent;
            width: 100%;
            left: 0;
            bottom: 1rem;
            height: 1px;
            transition: background-color .4s cubic-bezier(0.77,0.2,0.05,1.0);
        }
        

        &:hover::after, &:focus::after, &:active::after{
            background-color: $font-blue;
            transition: background-color .4s cubic-bezier(0.77,0.2,0.05,1.0);
        }

        &-active{
            color: $font-blue-dark;
            border: none;
            transition: color .4s cubic-bezier(0.77,0.2,0.05,1.0);
            
            &::after{
                background-color: transparent;
            }

            &:hover::after, &:focus::after, &:active::after{
                background-color: $font-blue;
                transition: background-color .4s cubic-bezier(0.77,0.2,0.05,1.0);
            }

                        
        }
    }
}




// RESPONSIVE 0 TO LG
// ________________________________________________________________________________________


@media screen and (max-width: 992px) {

    .fixed-menu-sm{
        position: fixed;
    }

    .burger{
        &-container{
            z-index: 4;
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        }
        
        &-bar{
            background-color: $font-base;
            display: block;
            height: 0.4rem;
            width: 4rem;
            margin-bottom: 0.8rem;
            border-radius: 0.3rem;
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                        opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
            
            &:first-child{
                transform-origin: 0% 0%;
            }

            &:last-child{
                transform-origin: 0% 100%;
                margin-bottom: 0;
            }
        }
        
    }

    .navigation{
        position: absolute;
        pointer-events: none;
        opacity: 0;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $black-overlay;
        transition: opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

        &-container{     
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100vh;
            display: flex;
            justify-content: center;
            background: url('src/img/illus/spiral.svg') no-repeat, $bg-blue-light;
            background-position: bottom -150px left -100px  ;
        }

        &-menu{
            display: flex;
            flex-flow: column wrap;
            margin: auto;
            align-items: center;
        
            &__el{
                margin-bottom: 1.6rem;
                list-style: none;
            }
        }
    }

    body{
        &.active-burger{
            overflow: hidden;   

            .burger{
                &-container{
                    transform: translateX(0.5rem);
                    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
                    
                    & .burger-bar{
                        &:first-child{
                        transform: rotate(45deg) translate(0, -2px);
                        }
                        
                        &:nth-child(2){
                        opacity: 0;
                        transform: scale(0);
                        }
                        
                        &:last-child{
                        transform: rotate(-45deg) translate(0, 2px);
                        }
                    } 
                }
            }

            .navigation{
                pointer-events: all;
                opacity: 1;
                transition: opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

            }
        }
        
    }
}

// RESPONSIVE MD TO LG
// ________________________________________________________________________________________


@media screen and (min-width: 768px) and (max-width: 991px) {
    .navigation{
        &-container{
            width: 50%; 
        }
    } 

    .menu{
        &-overlay{
            position: absolute;
            display: none;
            opacity: 0;
            z-index: 2;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .5);
            transition: opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        }
        
        &-container{
            transition: background-color 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
        }

        &-logo{
            transition: opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
            opacity: 1;
        }
    }

    body{
        &.active-burger{
            
            .menu{
                &-overlay{
                    display: block;
                    opacity: 1;
                    transition: opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
                }
                &-container{
                    transition: background-color 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
                    background-color: transparent;
                }
                &-logo{
                    transition: opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
                    opacity: 0;
                }
            }
        }
    }    
}


@media screen and (min-width: 992px) {

    .navigation{
        &-menu{
           display: inline-flex; 
        }

        &-link{
            font-size: 1.6rem;
            padding: 1.2rem;
            
            &:not(&__contact)::after{
                width: calc(100% - 2.4rem);
                left: 50%;
                bottom: .8rem;
                transform: translate(-50%);
            }
            
            &:hover:not(&__contact), &:focus:not(&__contact), &:active:not(&__contact){
                text-decoration: none; 
            }
            
            &__contact{
                text-decoration: none;
                background: transparent;
                cursor: pointer;
                border: none;
                color: $font-base;
                font-size: 1.6rem;
                padding: 10px 20px;
                border-radius: 20px;
                position: relative;
                transition: color .4s ease;
                
                
                &::before, &::after{
                    position: absolute;
                    border-radius: 20px;
                    height: 100%;
                    top: 0;
                    content: "";
                }
                
                &::before{
                    background-color: $bg-btn;
                    transition: transform .4s ease;
                    left: 0;
                    width: 100%;
                    z-index: -1;
                }
                
                &::after{
                    background-color: $font-green;
                    transition: background-color .4s ease;
                    left: -3px;
                    width: calc(100% + 6px);
                    z-index: -2;
                    top: 0;
                }
                
                &:hover, &:focus, &:active, &--active{
                    color: #E9F2F4;
                    text-decoration: none;
                    transition: color .4s ease;

                    &::before{
                        transform: scale(0);
                    }
                    
                    &::after{
                        background-color: $font-blue;
                    }
                }
            }
        }
    }
}