// PROJECT CORNERS STYLE
// __________________________________________________________



.rounded-corners{
    border-radius: 50px;

    &__notl{
        border-top-left-radius: 0;
    }

    &__notr{
        border-top-right-radius: 0;
    }

    &__nobl{
        border-bottom-left-radius: 0;
    }

    &__nobr{
        border-bottom-right-radius: 0;
    }
    
    &__notop{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    
    &__nobot{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}