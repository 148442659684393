// PROJECT REVIEWS STYLE
// __________________________________________________________

.review {
	margin-bottom: 50px;

	&:last-of-type() {
		margin-bottom: 0;
	}

	&-header {
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		align-items: center;
		margin-bottom: 2.4rem;
	}

	&-user-pic {
		@include box(7.6rem);
		margin-bottom: 1.8rem;
	}

	&-user {
		font-family: 'Montserrat Alternates', sans-serif;
		color: $font-green;
		text-align: center;
		font-size: 1.8rem;
		font-weight: 700;
		line-height: 1.5;
		margin-bottom: 1.8rem;
	}

	&-stars {
		display: flex;
		flex-flow: row wrap;
		justify-content: left;
		align-items: center;
		margin-bottom: 2.4rem;
	}

	&-star {
		position: relative;
		@include box(1.4rem);

		& .star {
			& path {
				stroke: none;
				fill: rgba(0, 0, 0, 0);
			}

			& .star-outline-path {
				fill: $bg-stars;
			}
		}

		&__active {
			& .star {
				& path {
					fill: $bg-stars;
				}
			}
		}
	}

	&-star + &-star {
		margin-left: .4rem;
	}

	&-text {
		max-height: 17rem;
		overflow: hidden;
		-webkit-box-orient: vertical;
		display: block;
		display: -webkit-box;
		overflow: hidden !important;
		text-overflow: ellipsis;
        -webkit-line-clamp: 6;
		transition: max-height .5s cubic-bezier(0.77,0.2,0.05,1.0);
        
		&--open {
            max-height: 100%;
            -webkit-line-clamp: inherit;
            
		}
	}

	&-more {
		display: block;
		margin-left: auto;
		text-align: center;
		padding: .5rem 2.4rem .5rem 1rem;
		text-decoration: none;
		background: none;
		color: $font-green-dark;
		border: none;

		&:hover,
		&:active,
		&:focus {
			color: $font-blue-dark;
			outline: none;
		}
	}
}
