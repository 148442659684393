// PROJECT BUTTONS STYLE
// __________________________________________________________


.btn{
    text-decoration: none;
    background: transparent;
    display: inline-block;
    cursor: pointer;
    z-index: 2;
    border: none;
    color: $font-base;
    font-size: 1.6rem;
    padding: 10px 20px;
    border-radius: 20px;
    position: relative;
    transition: color .4s cubic-bezier(0.77,0.2,0.05,1.0);
    
    
    &::before, &::after{
        position: absolute;
        border-radius: 20px;
        height: 100%;
        top: 0;
        content: "";
    }
    
    &::before{
        background-color: $bg-btn;
        transition: transform .4s cubic-bezier(0.77,0.2,0.05,1.0);
        left: 0;
        width: 100%;
        z-index: -1;
    }
    
    &::after{
        background-color: $font-green;
        transition: background-color .4s cubic-bezier(0.77,0.2,0.05,1.0);
        left: -3px;
        width: calc(100% + 6px);
        z-index: -2;
        top: 0;
    }
    
    &:hover, &:focus, &:active{
        // text-decoration: none;
        box-shadow: none;
        color: #E9F2F4;
        transition: color .4s cubic-bezier(0.77,0.2,0.05,1.0);

        &::before{
            transform: scale(0);
        }
        
        &::after{
            background-color: $font-blue;
        }
    }

    &__card-customer{
        &::after{
            background-color: $font-blue;
        }

        &:hover{
            &::after{
                background-color: $font-green;
            }
        }
    }

    &__form{
        color: inherit;
        & input{
            outline: none;
            border: none;
            background: transparent;
            transition: color .4s cubic-bezier(0.77,0.2,0.05,1.0);
            color:$font-base;
        }

        &:hover, &:focus, &:active{
            & input{
                color: #E9F2F4;
                transition: color .4s cubic-bezier(0.77,0.2,0.05,1.0);
            }
        }
        
    }
}



.link{
    text-decoration: none;
    color: $font-green;
    border-bottom: 1px solid $font-blue-dark;
    position: relative;
    transition: color .4s cubic-bezier(0.77,0.2,0.05,1.0),
                border-bottom-color .4s cubic-bezier(0.77,0.2,0.05,1.0);

    &:hover, &:focus, &:active{
        text-decoration: none;
        border-bottom-color: $font-green;
        color: $font-base;
        transition: color .4s cubic-bezier(0.77,0.2,0.05,1.0),
                    border-bottom-color .4s cubic-bezier(0.77,0.2,0.05,1.0);
        
    }    
}