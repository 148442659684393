.form{
    &-element{
        margin-bottom: 2rem;

        &--name{
            display: none;
        }
    }

    &-element label{
        font-size: 1.8rem;
        margin-bottom: .8rem;
        color: $font-blue;
    }

    &-element label, &-element input, &-element textarea{
        display: block;
        width: 100%;
        text-decoration: none;
        
    }
    
    &-element input, &-element textarea{
        border: 1px solid $font-blue;
        border-radius: 5px 0 5px 5px;
        background-color: $bg-blue-light;
    }

    &-element input, &-element textarea{
        padding: 1rem;
       
    }
}