.illu{
    @include pseudo-el();
    @extend %rempimg;
    
    &__touch{
        background: url('src/img/illus/header-finger.svg') center/contain no-repeat;
        height: 9rem;
        width: 7.5rem;
        z-index: 1;


        &--green{
            top: -4rem;
            right: 2rem;
        }

        &--white{
            background: url('src/img/illus/accompagnement-finger.svg') center/contain no-repeat;
            bottom: -4rem;
            right: 2rem;
        }
    }
    
}




// RESPONSIVE MD
// ________________________________________________________________________________________


@media screen and (min-width: 768px){
    .illu{
        &__touch{   
            height: 9rem;
            width: 7.5rem;
        }

        &__rock{
            background: url('src/img/illus/rock.svg') center/contain no-repeat;
            height: 10.5rem;
            width: 14rem;
            z-index: -1;
            top: -5rem;
            right: -6rem;
        }
    }
}




// RESPONSIVE MD
// ________________________________________________________________________________________


@media screen and (min-width: 992px){
    .illu{
        &__touch{
            height: 13rem;
            width: 10rem;

            &--green{
                top: -8.5rem;
                right: -2rem;
            }

            &--white{
                right: -4rem;
                
            }
        }
    }
}