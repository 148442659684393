// BOOTSTRAP OVERRIDED STYLE
// __________________________________________________________



// Put the code for overriding Bootstrap styles here 
// __________________________________________________________


// bootstrap spacing overriding

$spacer: 0.1rem !default;
$spacers: (
    0: 0
);

@for $i from 1 through 250{
    $spacers: map-merge($spacers,  (
        $i: ($i * $spacer)
    ));
}
