// PROJECT HEADER STYLE
// __________________________________________________________


.header{
    position: relative;

    &-illus-container{
        pointer-events: none;
        @extend %rempimg;
        @include pseudo-el();
        @include box(100%);
        right: 0;
        bottom: 0;

        &::after{
            @include pseudo-el();
            background: url('src/img/illus/hero-form.svg') no-repeat;
            background-size: contain;
            background-position: right bottom;
            width: 100%;
            height: 100%;
            bottom: 0;
            right: 0;
            z-index: -3;
        }

        &__accompagnement{

            &::before{
                @include pseudo-el();
                background: url('src/img/illus/accompagnement-shape-blue.svg') no-repeat;
                background-size: contain;
                background-position: left bottom;
                width: 100%;
                height: 100%;
                bottom: 0;
                left: 0;
                z-index: -3;
            }

            &::after{
                background: url('src/img/illus/accompagnement-shape-green.svg') no-repeat;
                background-size: contain;
                background-position: right bottom;
            }
        }
        &__offres{

            &::before{
                @include pseudo-el();
                background: url('src/img/illus/offres-shape-blue.svg') no-repeat;
                background-size: contain;
                background-position: left bottom;
                width: 100%;
                height: 100%;
                bottom: 0;
                left: 0;
                z-index: -3;
            }

            &::after{
                background: url('src/img/illus/offres-shape-green.svg') no-repeat;
                background-size: contain;
                background-position: right bottom;
            }
        }

        &__contact{

            &::before{
                @include pseudo-el();
                background: url('src/img/illus/contact-shape-blue.svg') no-repeat;
                background-size: contain;
                background-position: left bottom;
                width: 100%;
                height: 100%;
                bottom: 0;
                left: 0;
                z-index: -3;
            }

            &::after{
                background: url('src/img/illus/contact-shape-green.svg') no-repeat;
                background-size: contain;
                background-position: right bottom;
            }
        }
    }
}



// RESPONSIVE MD
// ________________________________________________________________________________________


@media screen and (min-width: 768px){
    .accompagnement{
        .header{
            overflow-x: hidden;
        }
    }
    .header{
        &-illus-container{

            &__home{
                width: 72%;
                height: 100%;
            }

            &__accompagnement{

                &::before{
                    width: 21%;
                    background-size: contain;
                }    
            }
            &__offres{
                &::before{
                    width: 15%;
                    background-size: contain;
                }    
            }
        }
    }
}




// RESPONSIVE XL
// ________________________________________________________________________________________


@media screen and (min-width: 1200px){
    .header{
        max-width: 1920px;
        margin: 0 auto;
    }
}