body, ul, ol, p, address, blockquote{
    margin: 0;
    padding: 0;
}

ul, ol{
    text-decoration: none;
    list-style-type: none;
}


a{
    outline: none;
}
