// PROJECT FONTS
// __________________________________________________________


// Import fonts url here (execpt font face, do it in font.scss)
// __________________________________________________________

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,700&display=swap'),
        url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;700&display=swap');




// Typographic rules here
// __________________________________________________________

html { 
    font-size: 62.5%;
} 

body { 
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 1.5; 
}

.list{
    &-disc{
        & li{
            list-style-type: disc;
        }
    }
}

.title { 
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 700;
    font-size: 2.4rem;
    margin-bottom: 2.4rem;
    line-height: 1.5;

    &__header{
        font-size: 2.8rem;
        margin-bottom: 2.8rem;
    }

    &__card{
        margin-top: 3.2rem;
    }
}

.title-footer{
    font-family: 'Montserrat Alternates', sans-serif;
    color: $font-green;
    padding-left: 1.6rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
}

.font-clr{
    &-green{
        color: $font-green;
        
        &-dark{
            color: $font-green-dark;
        }
    }

    &-blue{
        color: $font-blue;
        
        &-dark{
            color: $font-blue-dark;
        }
    }

    &-pink{
        color: $font-pink;
    }
}

.quote{
    font-family: 'Montserrat Alternates', sans-serif;
    position: relative;
    font-size: 2rem;
    line-height: 1.5;

    &-autor{
        text-align: right;
    }

    &::before, &::after{
        @include pseudo-el();
        width: 2.3rem;
        height: 3rem;
        background: url('src/img/illus/parentheses-quote.svg') no-repeat;
        background-size: contain;
        z-index: 1;
    }

    &::before{
        top: -3.5rem;
        left: -1rem;
        transform: rotate(-180deg);
    }
    &::after{
        bottom: -.8rem;
        right: -1rem;
    }
}

.text-standout{
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 700;
}




// RESPONSIVE MD
// ________________________________________________________________________________________


@media screen and (min-width: 768px){
    .quote{
        &::before, &::after{
            @include pseudo-el();
            width: 2.9rem;
            height: 3.7rem;        
        }
    
        &::before{
            top: -1.8rem;
            left: -4.5rem;
        }
        &::after{
            bottom: -1.8rem;
            right: -4.5rem;
        }
    }

    .title{
        &__offers-pro{
            margin: 0;
        }
    }
}




// RESPONSIVE LG
// ________________________________________________________________________________________


@media screen and (min-width: 992px){
    .title { 
        font-size: 3.2rem;
        margin-bottom: 3.2rem;
        
        &__header{
            font-size: 3.6rem;
            margin-bottom: 3.6rem;
        }

        &__card{
            margin-top: 4.2rem;
        }

        &__offers-pro{
            margin: 0;
        }
        
    } 

    .text-standout{
        font-size: 2.7rem;
    }
}


// RESPONSIVE XL
// ________________________________________________________________________________________


@media screen and (min-width: 1024px){
    .title { 
        font-size: 4.2rem;
        margin-bottom: 4.2rem;
        
        &__header{
            font-size: 4.8rem;
            margin-bottom: 4.8rem;
        }

        &__card{
            font-size: 3.2rem;
            margin-bottom: 3.2rem;
            margin-top: 5rem;
        }

        &__video, &__offers-pro{
            font-size: 3.2rem;
        }

        &__offers-pro{
            margin: 0;
        }

        &__reviews{
            font-size: 3.2rem;
        }
    } 

    .quote{
        font-size: 2.4rem;
    }

    .text-standout{
        font-size: 3.2rem;
    }
}
