// PROJECT VARIABLES
// __________________________________________________________


// COLORS
// __________________________________________________________


//fonts
$font-base: #2E2E2E;
$font-blue: #4B9FBC;
$font-bluegrey: #76889D;
$font-blue-dark: #5381B8;
$font-green: #009581;
$font-green-dark: #07848F;
$font-pink: #C27282;
$font-yellow: #F3BE0F;


// backgrounds
$bg-blue-light: #E6EFF2;
$bg-green-light: #E6F2F1;
$bg-pink-light: #F2E6E9;
$bg-btn: #F5F5F7;
$bg-stars: #f9b80b;


// overlay
$black-overlay : rgba(255, 255, 255, .7);