.menu-logo{
    @extend %rempimg;
    background: url('./src/img/logo/logo-mobile.svg') no-repeat;
    background-size: contain;
    height: 65px;
    width: 65px;
}


// RESPONSIVE LG
// ________________________________________________________________________________________


@media screen and (min-width: 992px){
    .menu-logo{
        background: url('./src/img/logo/logo.svg') no-repeat;
        background-size: contain; 
        width: 120px;
    }
}