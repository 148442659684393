// PROJECT FOOTER STYLE
// __________________________________________________________


.footer{
    font-size: 1.4rem;
    color: $font-bluegrey;

    &-link{
        color: $font-bluegrey;
        border-bottom: 1px solid $font-blue-dark;
        position: relative;
        transition: color .4s cubic-bezier(0.77,0.2,0.05,1.0),
                    border-bottom-color .4s cubic-bezier(0.77,0.2,0.05,1.0);
        
        &-icon{
            width: 1.8rem;
            height: auto;
            position: absolute;
            top: calc(50%);
            transform: translateY(-50%);
            left: -3.4rem;
            
            & path{
                fill: $font-blue-dark;
                transition: fill .4s cubic-bezier(0.77,0.2,0.05,1.0);
            }
            
            &__map{
                top: 1px;
                transform: inherit;
            }
        }
        
        &:hover, &:focus, &:active{
            text-decoration: none;
            border-bottom-color: transparent;
            color: $font-base;
            transition: color .4s cubic-bezier(0.77,0.2,0.05,1.0),
                        border-bottom-color .4s cubic-bezier(0.77,0.2,0.05,1.0);
            
            & .footer-link-icon{
                & path{
                    fill: $font-green;
                    transition: fill .4s cubic-bezier(0.77,0.2,0.05,1.0);
                } 
            }
        }

        &__address{
            line-height: 2;
        }

        &-logo, &-logo:hover, &-logo:focus, &-logo:active{
            text-decoration: none;
        }

    }
    &-credits{
        &-link{
            text-decoration: none;
            color: $font-bluegrey;
            transition: color .4s cubic-bezier(0.77,0.2,0.05,1.0);
            
            &:hover, &:focus, &:active{
                text-decoration: none;
                color: $font-base;
            }
        }

    }

    &-logo{
        height: 5.8rem;
        width: auto;
        display: block;

        &__misa{
            height: 5.4rem;
        }

        & img{
            height: 100%;
            width: auto;
        }
    }

    &-network{
        position: relative;
        box-sizing: inherit;
        @include box(2.4rem);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem;

        &-icon{
            position: absolute;
            @include box(2rem);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // transition: fill .4s cubic-bezier(0.77,0.2,0.05,1.0);
            
            & path{
                fill: $font-blue-dark;
                transition: fill .4s cubic-bezier(0.77,0.2,0.05,1.0);
            }

            &__yt{
                top: 58%;

                & .path-white{
                    fill: white;
                    
                } 
            }
        }

        &:hover, &:focus, &:active{
            & .footer-network-icon{
                & path{
                    fill: $font-green;
                    transition: fill .4s cubic-bezier(0.77,0.2,0.05,1.0);
                }
                &__yt{
                    & .path-white{
                        fill: white;
                        transition: fill .4s cubic-bezier(0.77,0.2,0.05,1.0);
                    } 
                }
            }
        }

    }
}




// RESPONSIVE LG
// ________________________________________________________________________________________


@media screen and (min-width: 992px) {
    .footer{
        &-credits{
            border: none;

            & .footer-credits-icon{
                & path{
                    fill: $font-blue-dark;
                    transition: fill .4s cubic-bezier(0.77,0.2,0.05,1.0);
                }
            }

            &:hover, &:focus, &:active{
                & .footer-credits-icon{
                    & path{
                        fill: $font-pink;
                        transition: fill .4s cubic-bezier(0.77,0.2,0.05,1.0);
                    }
                }
            }
        }
    }
}





// RESPONSIVE XL
// ________________________________________________________________________________________


@media screen and (min-width: 1200px) {
    .footer{
        &-logo{
            height: 6.4rem;
    
            &__misa{
                height: 5.8rem;
                margin-top: .2rem;
            }
        }
    }
}