// PROJECT BASIC STYLES
// ________________________________________________________________________________________

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}


body{
  overflow-x: hidden;
  font-family: 'poppins';
}



img {
  max-width: 100%;
  height: auto;
}


.clr{
  &-bg{
    &-bluelight{
      background-color: $bg-blue-light;
    }

    &-greenlight{
      background-color: $bg-green-light;
    }

    &-pinklight{
      background-color: $bg-pink-light;
    }
  }
}

.content{
  &-box{
    &-60{
      width: 60%;
      height: auto;
    }
  }
}