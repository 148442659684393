.offers{
    & .header{
        overflow-x: hidden;
    }
}

.offer-table{
    width: 100%;
    
    &-mobile{
        width: 100%;
        
        & thead{
            width: 100%;
            display: flex;
            flex-flow: column nowrap;
        }

        & thead tr, & tbody tr{
            display: flex;
        }

        & thead th{
            flex-basis: calc(100% / 2);
        }

        & tbody{
            width: 100%;
            display: flex;
        }

        & tbody tr{
            flex-basis: calc(100% / 2);
            flex-flow: column wrap;
        }

        &__part-solo{
            & thead th, & thead th{
                flex-basis: calc(100% / 3);
            }
        }

        &__part-collective{
            & thead tr{
                & :last-child{
                    padding-left: 4rem;
                }
            }
            
            & tbody{
                & tr:last-child{
                    padding-left: 4rem;
                
                }
            }
        }

        &__pro{
            display: flex;
            flex-flow: row wrap;

            
            & thead, & tbody{
                width: 50%;
            }

            thead th{
                // flex-basis: inheritx;
                text-align: center;
            }

            & thead tr, & tbody tr{
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                flex-basis: 100%;
                width: 100%;
            }

            & tbody td{
                width: 70%;
            }
        }

        &-title{
            font-size: 2.2rem;
            font-weight: bold;
        }

        & .bilan{
            width: 100%;
            flex-basis: 100%;
        }
    }
}

.switch{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3.9rem;
    
    &-button {
        background-color: $font-blue-dark;
        outline: none;
        border: none;
        position: relative;
        margin-bottom: 0;
        display: block;
        width: 9.8rem;
        height: 4.8rem;
        border-radius: 2.3rem;
        transition: background-color .8s cubic-bezier(0.77,0.2,0.05,1.0);
        cursor: pointer;
        
        &::after {
            position: absolute;
            display: block;
            content: "";
            transition: transform .8s cubic-bezier(0.77,0.2,0.05,1.0);
            width: 3.6rem;
            height: 3.6rem;
            top: .6rem;
            left: 0;
            border-radius: 50%;
            background: white;
            transform: translateX(.6rem);
        }
    }
    
    &-title{
        position: absolute;
        text-align: center;
        display: block;
        left: 0;
        right: 0;
        top: 6rem;
        font-weight: bold;
        font-size: 1.8rem;
        letter-spacing: .5px;
        
        &--custs{
            opacity: 1;
            transform: scale(1);
            color: $font-blue-dark;
            transition: opacity .8s cubic-bezier(0.77,0.2,0.05,1.0),
                        transform .8s cubic-bezier(0.77,0.2,0.05,1.0);
        }
        
        &--pros{
            opacity: 0;
            transform: scale(.95);
            color: $font-green;
            transition: opacity .8s cubic-bezier(0.77,0.2,0.05,1.0),
                        transform .8s cubic-bezier(0.77,0.2,0.05,1.0);
        }
    }

    .display-pros{
        & .switch{
            &-button{
                background-color: $font-green;

                &::after {
                    transform: translateX(5.6rem);
                }
            }
            &-title{
                &--custs{
                    transform: scale(.95);
                    opacity: 0;
                }
                
                &--pros{
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }
    }

}

.customers{
    & .switch-customers{
        display: block;
    }

    & .switch-professionnals{
        display: none;
    }
}

.professionnals{
    & .switch-customers{
        display: none;
    }

    & .switch-professionnals{
        display: block;
    }

}



@media screen and (min-width: 768px){
    .offer-table{ 
        & th{
            padding-bottom: 2rem;
        }

        & td{
            padding-bottom: 1rem;
        }
        
        & tbody tr:last-child{
            & td{
                padding-bottom: 0;
            }
        }
        
        &--part{
            & thead th:first-child{
                flex-basis: 50%;
            }
    
            & thead th:not(:first-child){
                width: calc(50% / 3);
            }
        }


        
    }

}
