// PROJECT DEFAULT HOME PAGE STYLE
// __________________________________________________________


.numbers{
    position: relative;

    &::before, &::after{
        @include pseudo-el();
        @include box(20rem);
        background: url('src/img/illus/spiral.svg') no-repeat;
        background-size: contain;
        z-index: -1;
    }
    
    &::before{
        top: -90px;
        left: -90px;
    }
    
    &::after{
        bottom: -50px;
        right: -90px;
    }
}




// RESPONSIVE MD
// ________________________________________________________________________________________


@media screen and (min-width: 768px) {
    .numbers{
        
        &::after{
            bottom: 80px;
        }
    }
}




// RESPONSIVE LG
// ________________________________________________________________________________________


@media screen and (min-width: 992px) {
    .numbers{

        &::before, &::after{
            width: 30rem;
            height: 30rem;
        }

        &::before{
            z-index: 1; 
            top: -130px;
            left: -180px;           
        }
        
        &::after{
            bottom: 130px;
            right: -180px;
        }
    }
}




// RESPONSIVE XL
// ________________________________________________________________________________________


@media screen and (min-width: 1200px) {
    .numbers{
        
        &::before, &::after{
            width: 36rem;
            height: 36rem;  
            
        }
    
        &::before{
            top: -180px;
            left: -320px;
        }
        
        &::after{
            bottom: 210px;
            right: -160px;
        }
    }
}