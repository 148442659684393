.img-brdr-shapes{
    border-radius: 70px;
    position: relative;
    
    & img, & .gaetan-video, & iframe, &::before, &::after{
        border-radius: 50px;
    }

    &::before, &::after{
        @include pseudo-el();
        border: 1px solid;
        z-index: -2;
    }

    &::before{
        height: calc(100% - 16px);
        width: calc(100% - 16px);
        border-color: $font-blue;
    }

    &::after{
        height: calc(100% + 32px);
        width: calc(100% + 10px);
        border-color: $font-green;
    }

    &__notl{
        & img, & .gaetan-video, & iframe, &::before, &::after{
            border-radius: 0 50px 50px 50px;
        }
        
        &::before{
            top: -32px;
            left: -12px;
        }

        &::after{
            border-radius: 0 70px 70px 70px;
            top: -16px;
            left: 0;
        }
    }

    &__notr{
        & img, & .gaetan-video, & iframe, &::before, &::after{
            border-radius: 50px 0 50px 50px;
        }
        
        &::before{
            top: -32px;
            right: -12px;
        }

        &::after{
            border-radius: 70px 0 70px 70px;
            left: -10px;
            top: -16px;
        }
    }

    &__nobl{
        & img, & .gaetan-video, & iframe, &::before, &::after{
            border-radius: 50px 50px 50px 0;
        }
  
        &::before{
            bottom: -32px;
            left: -12px;
        }

        &::after{
            border-radius: 70px 70px 70px 0;
            top: -16px;
        }
    }

    &__nobr{
        & img, & .gaetan-video, & iframe, &::before, &::after{
            border-radius: 50px 50px 0 50px;
        }
        
        &::before{
            bottom: -32px;
            right: 0;
        }

        &::after{
            border-radius: 70px 70px 0 70px;
            top: -16px;
        }
    }

    

    &-blue-pink{
        &::before{
            border-color: $font-blue;
        }

        &::after{
            border-color: $font-pink;
        }
    }
    
    &-pink-green{
        &::before{
            border-color: $font-pink;
        }

        &::after{
            border-color: $font-green;
        }
    }

    &-pink-green{
        &::before{
            border-color: $font-pink;
        }

        &::after{
            border-color: $font-green;
        }
    }
}

.img{
    &-w{
        &-100{
            width: 100%;
        }
    }
}



// RESPONSIVE MD
// ________________________________________________________________________________________


@media screen and (min-width: 768px){
    .img-brdr-shapes{
        &::after{
            width: calc(100% + 16px);
        }

        &__notl, &__nobl{
            &::before{
                left: -24px;
            }
        }

        &__notr, &__nobr{
            &::before{
                right: -24px;
            }
            
            &::after{
                left: -16px;
            }

        }
    }
}